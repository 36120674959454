.MinHFtable {
    flex-grow: 1;
    max-width: fit-content;
    margin: auto;
}

/* Import the Awesomplete CSS */
@import 'awesomplete/awesomplete.css';

/* Add your custom styles to override Awesomplete default styles */
.awesomplete mark {
    background: none;
    color: #333; /* Text color for light mode */
}

.awesomplete li:hover mark {
    background: none;
    color: #333; /* Text color for light mode */
}

.App.dark .awesomplete li {
    color: #000; /* Text color for dark mode */
  }

table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ccc;
    background-color: white;
}

table th,
table td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ccc;
}

table th {
    background-color: #f0f0f0;
    font-weight: bold;
}

table tr:nth-child(even) {
    background-color: #f5f5f5;
}

table tr:hover {
    background-color: #e0e0e0;
}

/* Styles for dark mode */
.App.dark table {
    background-color: #333;
    border-color: #444;
}

.App.dark table th {
    background-color: #222;
    color: white;
    border-color: #444;
}

.App.dark table td {
    background-color: #444;
    color: white;
    border-color: #444;
}

.App.dark table tr:nth-child(even) {
    background-color: #555;
}

.App.dark table tr:hover {
    background-color: #666;
}

/* Adjust table cell background color */
table td {
    background-color: #fff; /* Set a different background color for table cells */
}

.App.dark table td {
    background-color: rgb(100, 100, 100); /* Set a different background color for dark mode table cells */
}
.App.dark .MuiPaginationItem-page {
    color: white; /* Set the color of the page numbers to white */
}

.App.dark .MuiPaginationItem-root.Mui-selected {
    background-color: rgb(100, 100, 100); /* Set background color of selected page number */
}

.App.dark .MuiPaginationItem-icon {
    color: white; /* Set the color of the arrow buttons to white */
}