.HitfactorScenario {
    flex-grow: 1;
    max-width:fit-content;
    margin: auto;
}

.even-row {
    background-color: #f2f2f2; /* or any other color for even rows */
  }
  
.odd-row {
    background-color: #52abe6; /* or any other color for odd rows */
  }

