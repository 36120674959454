/* Footer.css */

.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 60px; /* Set your desired height */
  }
  
  .footer-content {
    display: flex;
    gap: 20px;
  }
  
  .footer-link {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    transition: color 0.3s ease;
    margin-bottom: 10px;
  }
  
  .footer-link:hover {
    color: #007bff; /* Optional color change on hover */
  }
  