/* menu.css */



.menu {
    display: flex;
    flex-wrap: wrap;
  }
  
  .menu-item {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    color: #444;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 4px;
    border: 2px solid #3498db;
    flex: 1;
    text-align: center;
    display: flex; /* Center text vertically and horizontally */
    align-items: center;
    justify-content: center;
  }
  
  .menu-item:hover {
    color: #fff;
    background-color: #3498db;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
  }
  
  @media screen and (max-width: 768px) {
    .menu {
      flex-direction: column;
    }
    
    .menu-item {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  
  .menu-item.selected {
    background-color: #3498db; /* Highlighted background color */
    color: #fff; /* Text color when selected */
  }

  /* Dark mode styles */
.dark .menu {
    background-color: #333; /* Dark background color */
  }
  
  .dark .menu-item {
    color: #fff; /* Light text color */
    border-color: #ccc; /* Lighter border color */
  }
  
  .dark .menu-item:hover {
    background-color: #666; /* Darker background color on hover */
    color: #eee; /* Lighter text color on hover */
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1); /* Light shadow on hover */
  }
  
  .dark .menu-item.selected {
    background-color: #666; /* Highlighted background color */
    color: #eee; /* Text color when selected */
  }