.Output {
    display: block;
    padding: 8px; /* 1 * 8px */
    margin: 8px; /* 1 * 8px */
    background-color: #fff; /* Default background color */
    color: #333; /* Default text color */
    border: 1px solid;
    border-color: #ccc; /* Default border color */
    border-radius: 2px;
    font-size: 0.875rem; /* 14px */
    font-weight: 700; /* Bold */
  }
  
  .dark .Output {
    background-color: #666;
    border-color: 1px solid #ccc;
    color: white;
  }

.App {
    border-bottom: 100vh;
}

.input-field {
  padding: 1em;
  border: 0.2em solid #3498db;
  border-radius: 0.5em;
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.dark .buttons {
  padding: 0.8em;
  color: #fff;
  background-color: #777;
  border-radius: 0.4em;
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.dark .input-field {
  padding: 1em;
  border: 0.2em solid #fff;
  border-radius: 0.5em;
  font-size: 1em;
  transition: border-color 0.3s ease;
}

.dark .input-field:hover {
  padding: 1em;
  border: 0.2em solid #666;
  border-radius: 0.5em;
  font-size: 1em;
  transition: border-color 0.3s ease;
}
