.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}


/* Styles for both light and dark modes */
.App {
  text-align: center;
  /*min-height: 100vh;  Set the minimum height to fill the viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Apply dark background color to the body in dark mode */
.App.dark body {
  background-color: #333;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html.dark-mode {
  background-color: #333;
  color: #fff; 
}

.App.light {
  background-color: white;
  color: black;
}

.App.dark {
  background-color: #333;
  color: white;
}

.App.dark .footer {
  background-color: #333; /* Set the background color to a dark color */
  color: white; /* Set the text color to white */
}

.App.dark .footer a {
  color: white; /* Set the link text color to white */
}

/* Styles for the top part of the website in dark mode */
.App.dark .top-section {
  background-color: #333; /* Set the background color to a dark color of your choice */
}


.dark-mode-toggle {
  position: fixed;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #fff;
}

/* Add styles for the toggle button */
.toggle-button {
  position: fixed;
  top: 10px; /* Adjust the value to control the vertical positioning */
  right: 10px; /* Adjust the value to control the horizontal positioning */
  background: none;
  border: none;
  cursor: pointer;
  z-index: 999; /* Ensure it's above other elements */
}

/* Style the button image */
.toggle-button img {
  width: 20px; /* Adjust the size as needed */
  height: auto;
}

/* Remove default styles for the button */
.toggle-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.logo {
  max-width: 100%;
  height: auto;
}
